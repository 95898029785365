<template>
  <q-select
    :label="!beforeLabel ? label : undefined"
    use-input
    input-debounce="0"
    v-model="entity"
    option-label="DICT_NAME"
    option-value="DICT_NO"
    :options="DataList"
    @filter="onFilter"
    map-options
    emit-value
    lazy-rules
    :rules="rules"
    :dense="dense"
    :options-dense="optionDense"
    :menu-shrink="menuShrink"
    :filled="filled"
    :disable="disable"
    :readonly="readonly"
    :loading="Loading"
  >
    <template v-slot:before v-if="beforeLabel">
      <span class="text-subtitle2">{{ label }}</span>
    </template>
    <template v-slot:no-option>
      <q-item>
        <q-item-section class="text-grey">
          没有符合条件的结果
          <q-btn
            v-if="allowAppend"
            size="sm"
            dense
            color="primary"
            icon="fas fa-plus-square"
            @click="onAdd"
            >新增</q-btn
          >
        </q-item-section>
      </q-item>
    </template>
  </q-select>
</template>

<script>
import msg from "@/config/notify";
import { GetPDM_DICTList } from "@/api/pdm/dict";
export default {
  props: {
    types: {
      default: "",
      type: String,
    },
    label: {
      default: "",
      type: String,
    },
    valueObject: {
      default: null,
      type: Object,
    },
    beforeLabel: {
      default: false,
      type: Boolean,
    },
    valueID: {
      default: "",
      type: String,
    },
    valueCode: {
      default: "",
      type: String,
    },
    valueName: {
      default: "",
      type: String,
    },
    rules: {
      default: null,
      type: Array[Function],
    },
    allowAppend: {
      default: false,
      type: Boolean,
    },
    dense: {
      default: false,
      type: Boolean,
    },
    optionDense: {
      default: true,
      type: Boolean,
    },
    menuShrink: {
      default: false,
      type: Boolean,
    },
    filled: {
      default: false,
      type: Boolean,
    },
    disable: {
      default: false,
      type: Boolean,
    },
    readonly: {
      default: false,
      type: Boolean,
    },
  },
  computed: {
    entity: {
      get() {
        return this.valueID;
      },
      set(val) {
        this.$emit("update:valueID", val);
        if (val) {
          let data_info = this.DataList.find((v) => {
            return v.HospID == val;
          });
          if (data_info) {
            this.$emit("update:valueCode", data_info.DICT_NO);
            this.$emit("update:valueName", data_info.DICT_NAME);
          }
        }
      },
    },
  },
  mounted() {
    this.onLoadData();
  },
  data() {
    return {
      DataList: null,
      Loading: false,
      queryParm: {
        FilterString: "",
        Dict_Type:this.types
      },
    };
  },
  methods: {
    onLoadData() {
      this.Loading = true;
      GetPDM_DICTList(this.queryParm)
        .then((res) => {
          if (res.Code == 0) {
            this.DataList = res.Data;
          } else {
            msg.error(res.Message);
          }
        })
        .finally(() => {
          this.Loading = false;
        });
    },
    onFilter(e, update) {
      setTimeout(() => {
        update(
          () => {
            this.queryParm.FilterString = e;
            this.onLoadData();
          },
          (ref) => {
            if (e != "" && ref.options.length > 0) {
              ref.setOptionIndex(-1); // reset optionIndex in case there is something selected
              ref.moveOptionSelection(1, true); // focus the first selectable option and do not update the input-value
            }
          }
        );
      }, 200);
    },
  },
};
</script>
