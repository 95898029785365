<template>
  <q-card style="width: 600px; max-width: 60vw">
    <q-bar class="bg-primary text-white">
      <q-icon name="fas fa-keyboard" />
      <div>
        <small class="q-ml-sm text-size-sm">
          {{ PdmSmsEntity.PdmSmsName }}({{ PdmSmsEntity.PdmSmsID }})
        </small>
      </div>

      <q-space />

      <q-btn dense flat icon="close" v-close-popup>
        <q-tooltip>关闭</q-tooltip>
      </q-btn>
    </q-bar>

    <q-card-section class="q-pa-sm">
      <div class="row q-gutter-sm q-mb-sm">
        <select_hospInfo
          :dense="true"
          outlined
          class="col-7"
          label="选择机构"
          v-model="PdmSmsEntity.HOSPID"
          v-model:valueCode="PdmSmsEntity.HOSPID"
        />
        <q-input
          label="短信模板ID(TemplateID)"
          dense
          outlined
          class="col-4"
          v-model="PdmSmsEntity.SMS_TEMPLATEID"
        />
        <q-input
          label="腾讯云短信SecretId"
          dense
          outlined
          class="col-11"
          v-model="PdmSmsEntity.SMS_SECRETID"
        />
        <q-input
          label="腾需云短信SecretKey"
          dense
          outlined
          class="col-11"
          v-model="PdmSmsEntity.SMS_SECRETKEY"
        />

        <q-input
          label="短信SdkAppid:在短信控制台(应用管理),添加应用后生成的实际SdkAppid"
          dense
          outlined
          class="col-11"
          v-model="PdmSmsEntity.SMS_SDKAPPID"
        />
        <q-input
          label="短信签名:短信签名内容,使用 UTF-8 编码,必须填写已审核通过的签名,签名管理里"
          dense
          outlined
          class="col-11"
          v-model="PdmSmsEntity.SMS_SIGN"
        />
        <select_dict
          :dense="true"
          outlined
          class="col-7"
          types="2"
          label="短信类别"
          v-model="PdmSmsEntity.SMS_TYPE"
          v-model:valueCode="PdmSmsEntity.SMS_TYPE"
        />
      </div>
    </q-card-section>

    <q-card-actions align="right" class="bg-white text-teal">
      <q-btn flat label="取消" v-close-popup />
      <q-btn flat label="确定" @click="onOK" />
    </q-card-actions>
  </q-card>
</template>

<script>
import { mapActions, mapState } from "vuex";
import select_hospInfo from "@/views/pdm/comm/hospInfo_select.vue";
import select_dict from "@/views/pdm/comm/dict_select.vue";
export default {
  components: {
    select_hospInfo,
    select_dict,
  },
  props: {},
  computed: {
    ...mapState("PdmSms", ["PdmSmsShowDlg", "PdmSmsEntity", "PdmSmsList"]),
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    ...mapActions("PdmSms", ["actUpdatePdmSms", "actGetPdmSmsList"]),
    onOK() {
      this.actUpdatePdmSms({
        HospID: this.$store.getters["appUser/hospID"],
        CUser: this.$store.getters["appUser/userID"],
        Info: this.PdmSmsEntity,
      }).then((res) => {
        if (res.Code == 0) {
          setTimeout(()=> {
            this.$emit("onLoadData");
          }, 200);

          this.msg.success(res.Message);
        } else {
          this.msg.error(res.Message);
        }
      });
    },
  },
};
</script>
