/*------------------------------------------------
// File Name:PDM_DICT.js
// File Description:PDM_DICT API方法js接口
// Author:zxl
// Create Time:2022/12/05 18:58:48
//------------------------------------------------*/
import { axios } from '@/utils/request'

const api = {
   _dict: "/PDM_DICT"
}

export default api


//-------------------创建对象-------------------
export function CreatePDM_DICTEntity(parameter) {
    return axios({
        url: api._dict + "/CreatePDM_DICTEntity",
        method: 'post',
        data: parameter
    })
}

//-------------------获取列表-------------------
export function GetPDM_DICTList(parameter) {
    return axios({
        url: api._dict + "/GetPDM_DICTList",
        method: 'post',
        data: parameter
    })
}

//-------------------分页获取列表---------------
export function GetPDM_DICTListByPage(parameter) {
    return axios({
        url: api._dict + "/GetPDM_DICTListByPage",
        method: 'post',
        data: parameter
    })
}

//-------------------获取对象---------------
export function GetPDM_DICTEntity(parameter) {
    return axios({
        url: api._dict + "/GetPDM_DICTEntity",
        method: 'post',
        data: parameter
    })
}


//-------------------保存对象---------------
export function UpdatePDM_DICTEntity(parameter) {
    return axios({
        url: api._dict+ "/UpdatePDM_DICTEntity",
        method: 'post',
        data: parameter
    })
}

//-------------------删掉对象---------------
export function DeletePDM_DICTEntity(parameter) {
    return axios({
        url: api._dict + "/DeletePDM_DICTEntity",
        method: 'post',
        data: parameter
    })
}
